import { Outlet } from 'react-router-dom';
import { DateTime } from 'ts-luxon';

import IconUI from '@components/core/IconUI';
import Button from '@components/core/inputs/Button';
import Main from '@components/Main';
import { RoleCheck } from '@components/RoleCheck';
import RouterTabs from '@components/RouterTabs/RouterTabs';
import { Role } from '@store/Roles';


import style from './student_events.module.scss';

const tabs = [
    { name: 'Активные', path: '' },
    { name: 'Прошедшие', path: 'finished' }
];

const StudentEvents = () => {
    return (
        <Main>
            <div>
                <h1 className={style.title}>Мероприятия</h1>
            </div>

            <RoleCheck roles={[Role.Student, Role.Teacher]}>
                <div className={style.time}>
                    <IconUI typeIcon="instruction" />
                    {`Вы просматриваете мероприятия по своему местному времени ${DateTime.now().toFormat('ZZZZ (ZZZZZ)')}`}
                </div>
            </RoleCheck>

            <div className={style.tabs_wrapper}>
                <Button
                    size="middle"
                    className={style.tabs_wrapper__btn}
                    btnType="outline"
                    typeIcon="instruction"
                    href="https://vk.com/video_ext.php?oid=-211876474&id=456240375&hd=2&hash=e191e2ddce4b9320&autoplay=1"
                >
                    Инструкция по подключению
                </Button>
                <RouterTabs tabs={tabs} tabsType="btns" className={style.tabs}  />
            </div>
            <Outlet />
        </Main>
    );
};

export default StudentEvents;
