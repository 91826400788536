import { Uuid } from '@store/api/apiTypes';

export enum NoticeTypes {
    eventStartSoon = 'eventStartSoon',
    eventStartSoon15min = 'eventStartSoon15min',
    disciplineOpened = 'disciplineOpened',
    disciplineAdded = 'disciplineAdded',
    controlWorkVerified = 'controlWorkVerified',
    webinarAdded = 'webinarAdded',
    videoLectureAdded = 'videoLectureAdded',
    controlWorkAdded = 'controlWorkAdded',
}

export type NoticePayload = Record<string, any> | null;

export interface Notices {
    baseUUID: string,
    subject: string;
    userUUID: Uuid;
    noticeType: NoticeTypes;
    publishBegin: string
    payload: NoticePayload;
    uuid: Uuid;
    readed: boolean;
    createdAt: string;
}

export interface NoticesResponse extends Omit<Notices, 'payload'> {
    payload: string;
}
